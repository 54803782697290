.select-custom{
  padding: 14px 50px 14px 20px;
    border-radius: 12px;
    font-size: 18px;
    box-shadow: none;
    border-color: #ced4da;
    width: 100%;
    color: var(--body-text-color);
}

@media screen and (max-width: 767px) {
  .pb-70{
    padding-bottom: 70px !important;
  }
}
.btn-sm{
  padding: 5px 10px !important;
    font-size: 12px !important;
}
.suggesting-dropdown{
  position: absolute;
  background: #fff;
  padding: 13px;
  width: 97%;
  z-index: 999;
  box-shadow: 0px 0px 6px #22222260;
  border-radius: 5px;
  left: 1%;
}
.bs-toast{
  position: fixed;
    top: 10px !important;
    z-index: 9999;
    color: #fff;
}

  /* Custom styles for cards */
  .booking-card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 10px;
}
.booking-info {
    font-size: 0.8rem;
}
.booking-card img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.badge-status {
    font-size: 0.75rem;
    font-size: 0.75rem;
    line-height: 1.3;
    font-weight: 500;
}
hr{
  margin: 4px !important;
}